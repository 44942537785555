// middleware/auth-check.ts
export default defineNuxtRouteMiddleware(async (to, from) => {
    // Skip auth checks during hot module replacement in development
    // @ts-ignore
    if (import.meta.hot && import.meta.hot.data.isHotUpdate) {
        console.log("[Dev] Skipping auth check during hot reload");
        return;
    }

    // Handle server-side and client-side differently
    if (import.meta.server) {
        // On server-side, just let the request through
        // The client-side middleware will handle full auth checks
        console.log("[Server] Auth check middleware - allowing initial render");
        return;
    }

    // Client-side authentication checking
    console.log("[Client] Running auth check middleware");

    // Get required stores
    const identityStore = useIdentityStore();
    const accessStore = useAccessStore();

    // If not authenticated and not initializing, redirect to login
    if (!identityStore.isInitializing && !identityStore.isAuthenticated) {
        console.log("User not authenticated, redirecting to login");
        return navigateTo("/login");
    }

    // Check if the route requires specific access
    const requiresAdmin = to.meta.requiresAdmin;
    const requiresPartner = to.meta.requiresPartner;
    const requiresAccount = to.meta.requiresAccount;

    // If admin is required but user doesn't have admin access
    if (
        requiresAdmin &&
        !accessStore.isAdmin &&
        !identityStore.isInitializing &&
        identityStore.isAuthenticated
    ) {
        console.log("Admin access required but user is not an admin");
        return navigateTo("/unauthorized");
    }

    // Check partner access requirement
    if (
        requiresPartner &&
        !accessStore.hasPartnerAccess &&
        !identityStore.isInitializing &&
        identityStore.isAuthenticated
    ) {
        console.log("Partner access required but user is not a partner");
        return navigateTo("/unauthorized");
    }

    // Check account access requirement
    if (
        requiresAccount &&
        !accessStore.hasAccountAccess &&
        !identityStore.isInitializing &&
        identityStore.isAuthenticated
    ) {
        console.log(
            "Account access required but user doesn't have account access"
        );
        return navigateTo("/unauthorized");
    }

    // Let the page render - AuthGate will handle loading states and permission checks
    return;
});
